import React, { useState, useEffect } from "react";
import "./Styles/Styles.scss";
import { useParams } from "react-router-dom";
import PageLoading from "../../../Components/PageLoading/PageLoading";
import Header from "./Header/Header.js";
import SyonHeader from "./Header/SyonHeader.js";
import GetFund from "../../../Adapters/FundDetails.js";
import MainContent from "./MainContent/MainContent";
import PageNotFound from "../../404/PageNotFound";
import PageForbidden from "../../403/PageForbidden";

const FundDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error404, setError404] = useState(false);
  const [error403, setError403] = useState(false);

  const { fund } = GetFund(id, setLoading, setError403, setError404);

  const [dbFund, setDBFund] = useState(null);

  useEffect(() => {
    setDBFund(fund);
  }, [fund]);

  return loading ? (
    <PageLoading />
  ) : error404 === true ? (
    <PageNotFound />
  ) : error403 === true ? (
    <PageForbidden />
  ) : (
    <div className="container-xxl mt-2 pt-2 pb-5">
      { (fund && fund.link === 'syon') ? 
      <SyonHeader
      fund={dbFund}
      stage={dbFund !== null ? dbFund.investmentStatus : ""}
    />  : 
      <Header
        fund={dbFund}
        stage={dbFund !== null ? dbFund.investmentStatus : ""}
      /> 
      }
      <MainContent fund={dbFund} />
    </div>
  );
};
export default FundDetails;
